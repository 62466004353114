import React, { useEffect } from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";

import { RouteWithLayout } from "./RouteWithLayout";
import { NotFoundRedirect } from "./NotFoundRedirect";

import {
  Main,
  Fullscreen
} from "layouts";

import {
  AuthInfo,
  DbTest,
  Home,
  Login,
  NotFound
} from "views";



export const Routes = () => {

  const location = useLocation();


  useEffect(() => {
    document.title = `${location.pathname}${location.search}`;
  }, [location]);


  return (
    <Switch>

      <Redirect
        from = "/" exact
        to   = "/db"
      />

      <RouteWithLayout
        path   = "/login" exact
        layout = {Fullscreen}
        view   = {Login}
        authPublic
      />

      <RouteWithLayout
        path   = "/home" exact
        layout = {Main}
        view   = {Home}
      />

      <RouteWithLayout
        path="/db" exact
        layout={Main}
        view={DbTest}
      />

      <RouteWithLayout
        path   = "/authInfo" exact
        layout = {Main}
        view   = {AuthInfo}
        authPublic
      />

      {/* <RouteWithLayout
        path       = {"/routes/lines/:id"} exact
        layout     = {Minimal}
        view       = {RouteLineForm}
        viewParams = {{  } as RouteLineFormProps}
      /> */}

      {/* <Route path = "/users" component = {UsersRoute} /> */}

      <RouteWithLayout
        path   = "/not-found" exact
        layout = {Main}
        view   = {NotFound}
      // authPublic
      />

      <NotFoundRedirect />

    </Switch>
  );
}
