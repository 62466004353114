import React, { FC, useContext, useEffect, useState } from "react";
import JsonView from "react-json-view";
import { CognitoIdentityCredentials, config } from "aws-sdk";
import { AttributeValue, DynamoDB, DynamoDBClient, ListTablesCommand, QueryCommand, BatchGetItemCommand, BatchGetItemInput } from "@aws-sdk/client-dynamodb";

import { AuthContext } from "auth/AuthProvider";
import { Organisation } from "./types/Organisation";

const defaultParams = {
    TableName: "org_access",
    IndexName: "orgs_by_user",
    KeyConditionExpression: "user_id = :uid",
    ExpressionAttributeValues: {
      ":uid": { S: "???" }
    }
};


export const DbTest: FC<{}> = () => {

  //#region DECLARATIONS
  const { userInfo, isAuth } = useContext(AuthContext);
  
  const [inited, setInited] = useState(false);
  const [queryParams, setQueryParams] = useState<any>(defaultParams);
  const [queryResult, setQueryResult] = useState<any>({});
  //#endregion


  //#region HANDLERS
  const getResult = async () => {
    try {
      const orgs: Organisation[] = [];
      // debugger;
      const dbClient = new DynamoDBClient({ region: config.region, credentials: config.credentials as CognitoIdentityCredentials });
        const results = await dbClient.send(new QueryCommand(queryParams));
        // debugger;
        console.log(results);
        // results?.Items.forEach(function (element, index, array) {
        //   console.log(element);
        // });
        setQueryResult(results);

        // console.table(results.Items?.map(x=>x.org_id));

        if(results.Items?.length) {
          const orgIds = results.Items.map(x=>x.org_id?.S);

          const keys: { [key: string]: any }[] = orgIds.map(x => {
            return {
              "org_id": {
                S: x
              }
            }
          });

          const input: BatchGetItemInput = {
            RequestItems: {
              "org_details":{
                Keys: keys
              }
            }
          };
          const results2 = await dbClient.send(new BatchGetItemCommand(input));
          console.log(results2);

          debugger
          results2.Responses?.org_details?.forEach(x=> {
            const o = new Organisation(results.Items?.find(y=>y.org_id.S === x.org_id.S));
            o.fillDetails(x);
            console.log(o);
          });

        }

        

    } catch (err) {
        // debugger;
        console.error(err);
        setQueryResult(err);
    }
};
  //#endregion


  //#region EFFECTS
  useEffect(() => {
    if (userInfo && !inited) {
      setInited(true);
      setQueryParams({
        TableName: "org_access",
        IndexName: "orgs_by_user",
        KeyConditionExpression: "user_id = :uid",
        ExpressionAttributeValues: {
          ":uid": { S: userInfo.id }
        }
      });
    }
  }, [inited, userInfo]);
  //#endregion

  return (
    <>
      <h2>
        DB QUERY
        &nbsp;
        &nbsp;
        {/* <button onClick={() => setInited(false)}>Reset</button> */}
        {/* <button onClick={() => setQueryParams(createDefaultParams(userInfo!.id))}>Reset</button> */}
      </h2>
      <JsonView
        src              = {queryParams}
        onEdit           = {edit => setQueryParams(edit.updated_src)}
        enableClipboard  = {false}
        displayDataTypes = {false}
        collapsed        = {false}
        name             = {null}
        theme            = "monokai"
      />

      <h2>
        RESULT
        &nbsp;
        &nbsp;
        <button onClick={() => getResult()}>Get result</button>
        <button onClick={() => setQueryResult({})}>Clear</button>
      </h2>
      <JsonView
        src              = {queryResult}
        enableClipboard  = {false}
        displayDataTypes = {false}
        collapsed        = {false}
        name             = {null}
        theme            = "monokai"
      />
    </>
  );
};
