import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Organisation } from 'views/dev/types/Organisation';
import { AuthContext } from "auth/AuthProvider";

import { CognitoIdentityCredentials, config } from "aws-sdk";
import { AttributeValue, DynamoDB, DynamoDBClient, ListTablesCommand, QueryCommand, BatchGetItemCommand, BatchGetItemInput } from "@aws-sdk/client-dynamodb";
import { useContext, useEffect, useState } from 'react';
import { Card } from '@material-ui/core';
import { PutItemInput } from 'aws-sdk/clients/dynamodb';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '80%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: theme.typography.fontWeightRegular,
    },
    acc: {
      "&.Mui-expanded" : {
        background: "whitesmoke"
      }
    },
    summ: {
      "& p": {
        transition: "font-size .1s"
      },
      "&.Mui-expanded": {
        color: "var(--color-iri-dark)",
        "&>.MuiAccordionSummary-content": {
          margin: "0px"
        },
        "& p": {
          fontSize: "28px !important"
        }
      },
     
    },
    card: {
      padding: "0 15px",
      minWidth: "175px"
    }
    
  }),
);

export default function SimpleAccordion() {

  const classes = useStyles();
  
  const { userInfo, isAuth, creds } = useContext(AuthContext);

  const [inited, setInited] = useState(false);
  const [orgs, setOrgs] = useState<Organisation[]>([]);


  const getResult = async () => {
    try {
      const res: Organisation[] = [];

      const queryParams = {
        TableName: "org_access",
        IndexName: "orgs_by_user",
        // KeyConditionExpression: "user_id = :uid",
        // ExpressionAttributeValues: {
        //   ":uid": { S: userInfo?.id }
        // }

        KeyConditionExpression: "user_id = :uid",
        ExpressionAttributeValues: {
          ":uid": { S: userInfo?.id }
        }

        //  KeyConditionExpression: "role = :rolex",
        // ExpressionAttributeValues: {
        //   ":rolex": { S: "test" }
        // }
      };

      console.log(config.credentials);
      console.log(creds);
      
      // const dbClient = new DynamoDBClient({ region: config.region, credentials: config.credentials as CognitoIdentityCredentials });
      const dbClient = new DynamoDBClient({ region: config.region, credentials: creds || config.credentials as CognitoIdentityCredentials });
      const results = await dbClient.send(new QueryCommand(queryParams as any));

      if (results.Items?.length) {
        const orgIds = results.Items.map(x => x.org_id?.S);

        const keys: { [key: string]: any }[] = orgIds.map(x => {
          return {
            "org_id": {
              S: x
            }
          }
        });

        const input: BatchGetItemInput = {
          RequestItems: {
            "org_details": {
              Keys: keys
            }
          }
        };
        const results2 = await dbClient.send(new BatchGetItemCommand(input));
        console.log(results2);

        results2.Responses?.org_details?.forEach(x => {
          const o = new Organisation(results.Items?.find(y => y.org_id.S === x.org_id.S));
          o.fillDetails(x);
          res.push(o);
        });

        console.log(res);

        setOrgs(res);

      }



    } catch (err) {
      // debugger;
      console.error(err);
    }
  };


  useEffect(() => {
    if(isAuth && userInfo && !inited) {
      // debugger;
      getResult();
      setInited(true);
    }
  }, [inited, isAuth, userInfo]);


  return (
    <div className={classes.root}>

      {
        orgs.map((o, idx) =>
          <Accordion key={idx} className={classes.acc}>
            <AccordionSummary
               className={classes.summ}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography className={classes.heading}>
                {o.orgName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{width:"100%", display:"flex", alignItems:"center", justifyContent:"space-evenly"}}>
                <Card className={classes.card}>
                  <h4 style={{color: "#36c2b5"}}>Subscription</h4>
                  <h1>{o.subscription}</h1>
                </Card >

                <Card className={classes.card}>
                  <h4 style={{color: "#36c2b5"}}>Month deploys</h4>
                  <h1>{`${o.currentMonthDeploys} / ${o.maxDeploysPerMonth}`}</h1>
                </Card >
              </div>
            </AccordionDetails>
          </Accordion>
        )
      }
      

      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Accordion 1</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Accordion 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget.
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      {/* <Accordion disabled>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Disabled Accordion</Typography>
        </AccordionSummary>
      </Accordion> */}
    </div>
  );
}