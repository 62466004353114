import React, { FC } from "react";

import css from "./Fullscreen.module.scss";


export const Fullscreen: FC = ({ children }) => {

  return (
    <>
      <main className={css.main}>
        {children}
      </main>
    </>
  );
}
