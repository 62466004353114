import React, { FC, useContext } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";

import { AuthContext } from "auth/AuthProvider";

import logoHead from "assets/logo/logo_head.png";
import { ReactComponent as LogOutIcon } from "assets/svg/log-out.svg";

import css from "./Topbar.module.scss";


export const Topbar: FC<{ className: string }> = ({ className }) => {

  //#region DECLARATIONS
  const { logOut,  isAuth, userInfo } = useContext(AuthContext);
  //#endregion


  //#region HANDLERS
  //#endregion


  //#region EFFECTS
  //#endregion

  return (
    <>
      <nav className={clsx(className, css.nav)}>
        <Link to="/home">
          <img className={css.logo} src={logoHead} alt="iri6" />
        </Link>

        <div className={css.navMenu}>
          <Link to="/home">Organisations</Link>
          {/* <Link to="/db">db</Link> */}
          <Link to="/test">nodef</Link>
          <Link to="/login">login</Link>
        </div>

        {
          isAuth && userInfo &&
          <>
            <Link to="/authInfo" data-tip data-for="userInfo">{userInfo.username}</Link>
            <ReactTooltip id="userInfo" type="dark" effect="solid" place="left">
              <div>
                <div>{userInfo.id}</div>
                <div>{userInfo.email}</div>
                <div>loggedAt: {format(userInfo.loggedAt, "EEE, dd MMM yyyy HH:mm:ss")}</div>
                {/* <div>authExp: {format(userInfo.authExp, "EEE, dd MMM yyyy HH:mm:ss")}</div> */}
              </div>
            </ReactTooltip>

            <LogOutIcon className={css.logOut} onClick={() => logOut()} />
          </>
        }
      </nav>
    </>
  );
};
