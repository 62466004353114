
import { AttributeValue, DynamoDB, DynamoDBClient, ListTablesCommand, QueryCommand } from "@aws-sdk/client-dynamodb";



export const callDb3 = async (credentials: any) => {

    // https://docs.aws.amazon.com/sdk-for-javascript/v3/developer-guide/dynamodb-example-query-scan.html

    // Set the AWS Region
    const REGION = "us-east-1"

    // Set the parameters
    const params = {
        TableName: "org_access",
        IndexName: "orgs_by_user",
        KeyConditionExpression: "user_id = :uid",
        ExpressionAttributeValues: {
            ":uid": { S: "94858748-179c-4fb5-ad63-2608403f4dad" }
            // ":uid": { S: "b69f9afb-db11-4acd-919f-7319949fc254" }
        },
    };

    // var dynamodb = new AWS.DynamoDB({region: REGION, credentials}); 
    // dynamodb.query(params, (err, data) => {
    //   if (err) {
    //     console.log("Error", err);
    //   } else {
    //     console.log("Success", data);
    //   }
    // });


    const dbclient = new DynamoDBClient({ region: REGION, credentials });
    const run = async () => {
        try {
            const results = await dbclient.send(new QueryCommand(params));
            // debugger;
            console.log(results);
            // results?.Items.forEach(function (element, index, array) {
            //   console.log(element);
            // });
        } catch (err) {
            // debugger;
            console.error(err);
        }
    };
    run();
};
