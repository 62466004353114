export class Organisation {


    private _orgId: string;
    get orgId() { return this._orgId }

    private _userId: string;
    get userId() { return this._userId }

    private _role: string;
    get role() { return this._role }

    private _policy: object;
    get policy() { return this._policy }

    private _billingId?: string;
    get billingId() { return this._billingId }

    private _currentMonthDeploys?: number;
    get currentMonthDeploys() { return this._currentMonthDeploys }

    private _maxDeploysPerMonth?: number;
    get maxDeploysPerMonth() { return this._maxDeploysPerMonth }

    private _orgName?: string;
    get orgName() { return this._orgName }

    private _subscription?: string;
    get subscription() { return this._subscription }



    constructor(data: any) {
        this._orgId = data.org_id?.S || undefined;
        this._userId = data.user_id?.S || undefined;
        this._role = data.role?.S || undefined;
        this._policy = JSON.parse(data.policy?.S || "{}");
    }


    fillDetails(data:any) {
        this._billingId = data.billing_id?.S || undefined;
        this._currentMonthDeploys = parseInt(data.current_month_deploys?.N);
        this._maxDeploysPerMonth = parseInt(data.max_deploys_per_month?.N);
        this._orgName = data.org_name?.S || undefined;
        this._subscription = data.subscription?.S || undefined;
    }

}