import React, { FC, useContext } from "react";

import logo from "assets/logo/logo192.png";
import Organisations  from "views/Organisations/Organisations";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, styled, TextField, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
// import { PutItemInput } from "aws-sdk/clients/dynamodb";
import { AttributeValue, DynamoDB, DynamoDBClient, ListTablesCommand, QueryCommand, BatchGetItemCommand, BatchGetItemInput, PutItemCommand, PutItemInput } from "@aws-sdk/client-dynamodb";
import { CognitoIdentityCredentials, config } from "aws-sdk";
import { AuthContext } from "auth/AuthProvider";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));


const BootstrapDialogTitle:FC<any> = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other} style={{minWidth:"400px"}}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "gray"
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


export const Home: FC<{}> = () => {

  //#region DECLARATIONS
  const [open, setOpen] = React.useState(false);
  const [newSubs, setNewSubs] = React.useState(0);
  const [newOrgName, setNewOrgName] = React.useState("");

  const { userInfo } = useContext(AuthContext);
  //#endregion


  //#region HANDLERS
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    console.log(newSubs);
    console.log(newOrgName);
    addOrg();
  };

  const addOrg = async () => {
    const orgId = "75f6e3e5-bb51-5783-8d1a-84ca27a57e943";
    const input: PutItemInput = {
      TableName: "org_details",
      Item: {
        "org_id" : {
          S: orgId,
        },
        "org_test" : {
          S: "1345"
        },
        "org_name": {
          S: newOrgName
        },
        "subscription": {
          S: `Tier ${newSubs}`
        }
      },
      // ReturnValues
      ReturnConsumedCapacity: "TOTAL",
      // ConditionExpression: "attribute_not_exists(org_id)"
    };
    const input2: PutItemInput = {
      TableName: "org_access",
      Item: {
        "org_id" : {
          S: orgId,
        },
        "user_id" : {
          S: `${userInfo?.id}`
        },
      },
      // ReturnValues
      ReturnConsumedCapacity: "TOTAL",
      // ConditionExpression: "attribute_not_exists(org_id)"
    };

    debugger;

    try {

      
      
      const dbClient = new DynamoDBClient({ region: config.region, credentials: config.credentials as CognitoIdentityCredentials });
      const result = await dbClient.send(new PutItemCommand(input));
      console.log(result);
      const result2 = await dbClient.send(new PutItemCommand(input2));
      console.log(result2);
    }
    catch (exc:any) {
      console.log(exc);
    }
  };
  //#endregion


  //#region EFFECTS
  //#endregion

  return (
    <div style={{padding: "0 15px"}}>

      <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
        <h1>Organisations</h1>
        <Button color="primary" variant="contained" onClick={handleClickOpen}>CREATE</Button>
      </div>
      {/* <img src={logo} alt="logo" /> */}

      <BootstrapDialog
        onClose={() => handleClose()}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Create new organisation
        </BootstrapDialogTitle>
        <DialogContent dividers>
        <FormControl fullWidth>
          <TextField id="standard-basic" label="Organisation name" variant="standard" value={newOrgName} onChange={x=>setNewOrgName(x.target.value as string)}/>
        </FormControl>
        <FormControl fullWidth style={{margin:"32px 0"}}>
          <InputLabel id="demo-simple-select-label">Subscription</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newSubs}
            label="Age"
            onChange={x=>setNewSubs(x.target.value as number)}
          >
            <MenuItem value={0}>Tier 0</MenuItem>
            <MenuItem value={1}>Tier 1</MenuItem>
            <MenuItem value={2}>Tier 2</MenuItem>
          </Select>
        </FormControl>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" autoFocus onClick={handleClose}>
            SAVE
          </Button>
        </DialogActions>
      </BootstrapDialog>

      <div style={{display:"flex", justifyContent:"center"}}>
        <Organisations/>
      </div>

    </div>
  );
};
