import React, { FC } from "react";

import { Topbar } from "./components/Topbar";

import css from "./Main.module.scss";


export const Main: FC = ({ children }) => {

  return (
    <>
      <Topbar className={css.topbar} />

      <main className={css.main}>
        {children}
      </main>
    </>
  );
}