import React from "react";
import { BrowserRouter } from "react-router-dom"

import { AuthProvider } from "auth/AuthProvider";
import { Routes } from "router/Routes";

import "./assets/scss/styles.scss";


const App = () => {

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthProvider>
  );

}

export default App;
