import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import clsx from "clsx";

import { AuthContext } from "auth/AuthProvider";

import { FiAtSign, FiX } from "react-icons/fi";
import logo from "assets/logo/logo320.png";
import { ReactComponent as UserIcon } from "assets/svg/user.svg";
import { ReactComponent as KeyIcon } from "assets/svg/key.svg";
import { ReactComponent as LockIcon } from "assets/svg/lock.svg";
import { ReactComponent as UnlockIcon } from "assets/svg/unlock.svg";
import { ReactComponent as EyeIcon } from "assets/svg/eye.svg";
import { ReactComponent as EyeOffIcon } from "assets/svg/eye-off.svg";

import css from "./Login.module.scss";


// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     display: "flex"
//   }
// }));


interface LocationState {
  referrer: {
    pathname: string;
  };
}

type FormMode = "LogIn" | "Reg";


// export type RmComponentProps = {
//   id     : string
//   field1?: string
// }


export const Login = () => {

  //#region DECLARATIONS
  // const classes = useStyles();

  const history = useHistory();
  const location = useLocation<LocationState>();
  const { referrer } = location.state || { referrer: { pathname: "/" } };

  const { logIn, logInGoogle, signUp, resetAuthError, isAuth, authError, /* isAuth$ */ } = useContext(AuthContext);

  const [formMode, setFormMode] = useState<FormMode>("LogIn");
  const [email, setEmail] = useState("");
  const [usr, setUsr] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdVisible, setPwdVisible] = useState(false);

  const [progress, setProgress] = useState(false);
  //#endregion


  //#region HANDLERS
  const login = () => {
    setProgress(true);
    logIn(usr, pwd)
    .then(() => setProgress(false));
  };


  const signup = () => {
    signUp(email, usr, pwd);
  };


  const handleFormModeSwitch = (mode: FormMode) => {
    setFormMode(mode);
    resetAuthError();
    setPwdVisible(false);
    setPwd("");
    setEmail("");
    setUsr("");
  };
  //#endregion


  //#region EFFECTS
  useEffect(() => {
    if (isAuth) {
      history.replace(referrer);
    }
  }, [isAuth, history, referrer]);
  //#endregion

  function onGoogleSignIn(data: any) {
    // var profile = googleUser.getBasicProfile();
    // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + profile.getName());
    // console.log('Image URL: ' + profile.getImageUrl());
    // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    console.log(data);

    logInGoogle(data.tokenId, data.accessToken);
  }


  const ErrMsg = () => {
    return (
      <>
        {
          authError &&
          <div className={css.errMsg} >
            {authError.message}
            <FiX onClick={()=>resetAuthError()}/>
          </div >
        }
      </>
    );
  };


  return (
    <div className={css.root}>

      <img className={css.logo} src={logo} alt="logo" />

      <div className={css.formWrp}>
        <form className={clsx(css.form, formMode !== "Reg" && css.hidden)}>
          
        <ErrMsg/>
        
          <label>
            <FiAtSign />
            <input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
          </label>
          <label>
            <UserIcon />
            <input type="text" placeholder="Username" value={usr} onChange={e => setUsr(e.target.value)} />
          </label>
          <label>
            <KeyIcon />
            <input type={pwdVisible ? "text" : "password"} placeholder="Password" value={pwd} onChange={e => setPwd(e.target.value)} />
            {
              pwd.length > 0 &&
              <div className={css.eye} onClick={() => setPwdVisible(!pwdVisible)}>
                <EyeIcon className={pwdVisible ? "visible" : ""} />
                <EyeOffIcon className={!pwdVisible ? "visible" : ""} />
              </div>
            }
          </label>

          <button type="button" onClick={() => signup()}>
            Sign up
          </button>

          <div className={css.modeSwitch}>
            Do have an account, already? <span onClick={() => handleFormModeSwitch("LogIn")}>Log in</span>
          </div>

        </form>

        <form className={clsx(css.form, (formMode !== "LogIn" || progress) && css.hidden)}>
          
          <ErrMsg/>

          <label>
            <UserIcon />
            <input type="text" placeholder="Username / Email"  value={usr} onChange={e => setUsr(e.target.value)} />
          </label>
          <label>
            <KeyIcon />
            <input type={pwdVisible ? "text" : "password"} placeholder="Password" value={pwd} onChange={e => setPwd(e.target.value)} />
            {
              pwd.length > 0 &&
              <div className={css.eye} onClick={() => setPwdVisible(!pwdVisible)}>
                <EyeIcon className={pwdVisible ? "visible" : ""} />
                <EyeOffIcon className={!pwdVisible ? "visible" : ""} />
              </div>
            }
          </label>

          <button type="button" onClick={() => login()}>
            {/* {usr && pwd.length > 4 && <KeyIcon />} */}
          Log In
          {/* {progress && <LockIcon/>} */}
          </button>

          <div className={css.modeSwitch}>
            Don't have an account? <span onClick={() => handleFormModeSwitch("Reg")}>Sign up</span>
          </div>

          <div className={css.separator}>
            OR
        </div>

          <GoogleLogin
            clientId="932714005535-vo24p5avfg9l86j5g3uv0d7e0g25bofo.apps.googleusercontent.com"
            buttonText="Login using Google account"
            className={css.btnGoogle}
            onSuccess={onGoogleSignIn}
            onFailure={onGoogleSignIn}
            cookiePolicy={'single_host_origin'}
          />

        </form>
      </div>
    
    </div>
  );
};