export type AwsParams = {
    region        : string;
    IdentityPoolId: string;
    UserPoolId    : string;
    ClientId      : string;
};


export const iri6params: AwsParams = {
    region        : "us-east-1",                                        // od Honzu
    IdentityPoolId: "us-east-1_0vRF63ri9",                              // z tokenu
    UserPoolId    : "us-east-1:0d93dcd5-f1ee-4586-bbb1-03d949e347d6",   // od Honzu ??
    ClientId      : "3mp6mhkp2rnlkm65uq86aee64j"                        // z tokenu
};