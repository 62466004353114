import React, { FC, createContext, useState, useCallback, useEffect } from "react";
import { Subject, Observable } from "rxjs";
import { differenceInMinutes } from "date-fns";

import { CognitoIdentityCredentials, config } from "aws-sdk";

import { CognitoAuth } from "auth/CognitoAuth";
import { iri6params } from "auth/AwsParams";

const cognitoAuth = new CognitoAuth(iri6params);


type UserInfo = {
  id      : string;
  email   : string;
  username: string;
  loggedAt: Date;
  authExp : Date;
}

interface AuthService {
  logIn         : (usr: string, pwd: string) => Promise<boolean>;
  logInGoogle   : (idToken: string, accessToken: string) => void;
  logOut        : () => void;
  signUp        : (email: string, username: string, password: string) => Promise<boolean>;
  refreshAuth   : () => Promise<boolean>;
  checkAuth     : () => Promise<boolean>;
  resetAuthError: () => void;
  isAuth        : boolean | null;
  isAuth$       : Observable<boolean>
  authError     : any;
  userInfo      : UserInfo | null;
  creds : CognitoIdentityCredentials | null;
}

export const AuthContext = createContext({} as AuthService);



export const AuthProvider: FC = ({ children }) => {

  //#region DECLARATIONS
  const [isAuth, setIsAuth]       = useState<boolean | null>(null);
  const [authError, setAuthError] = useState<any>(null);
  const [isAuthSubject]           = useState(new Subject<boolean>());
  const [isAuth$]                 = useState(isAuthSubject.asObservable());
  const [userInfo, setUserInfo]   = useState<UserInfo | null>(null);
  const [authExp, setAuthExp]     = useState(new Date(0));
  const [creds, setCreds] = useState<CognitoIdentityCredentials |null>(null);
  //#endregion



  //#region HANDLERS
  const logIn = async (usr: string, pwd: string) => {
    const { success, error } = await cognitoAuth.authenticate(usr, pwd);
    
    return new Promise<boolean>((resolve) => {

      console.log("===================================================");
      console.log(config.credentials);
      console.log(cognitoAuth.Credentials);
      setCreds(cognitoAuth.Credentials ||null);
      console.log("===================================================");


      setTimeout(() => {
        // debugger;
        setAuthError(error);
        setIsAuth(success);
        isAuthSubject.next(success);
        // return success;
        resolve(success);
      }, success ? 0 : 0); // for some reason, afret login, it takes long time to config.credentials gets initialized (for real, to realy usefull state)
    });
  };

  const logInGoogle = (idToken: string, accessToken: string) => {
    cognitoAuth.initCredentialsGoogle(idToken, accessToken);
  };


  const logOut = () => {
    cognitoAuth.logout();
    setIsAuth(false);
    isAuthSubject.next(false);
  };


  const signUp = async (email: string, username: string, password: string) => {
    const {result, error} = await cognitoAuth.signup(email, username, password);
    debugger;
    setAuthError(error || null);
    console.log(error);
    console.log(result);

    return !!error;
  };


  const refreshAuth = useCallback(async () => {
    const expDate = await cognitoAuth.refreshAuth();
    if (expDate) {
      setAuthExp(expDate);
    }
    return expDate !== null;
  }, []);


  const checkAuth = useCallback(async () => {
    const res = await cognitoAuth.isAuthenticated();
    setIsAuth(res);

    if (differenceInMinutes(Date.now(), authExp) > 30) {
      refreshAuth();
    }

    return res;
  }, []);


  const resetAuthError = useCallback(async () => {
    setAuthError(null);
  }, []);
  //#endregion


  //#region EFFECTS
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const token = await cognitoAuth.getIdToken();
        if (token) {
          const payload = token.decodePayload();
          setUserInfo({
            id      : payload["sub"].toString(),
            email   : payload["email"].toString(),
            username: payload["cognito:username"].toString(),
            loggedAt: new Date(payload["auth_time"] * 1000),
            authExp
          });
        }
        else {
          setUserInfo(null);
        }
      }
      catch (err) {
        console.log(err);
      }
    };
    getUserInfo();
  }, [isAuth, authExp])
  //#endregion


  return (
    <AuthContext.Provider value={{ logIn, logInGoogle, logOut, signUp, refreshAuth, checkAuth, resetAuthError, isAuth, isAuth$, authError, userInfo, creds }}>
      {children}
    </AuthContext.Provider >
  );
}
