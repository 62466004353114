import React, { FC, FunctionComponent, useContext, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import { AuthContext } from "auth/AuthProvider";


type RouteWithLayoutProps = {
  exact?     : boolean,
  path       : string,
  layout     : FunctionComponent,
  view       : FunctionComponent,
  viewParams?: any,
  authPublic?: boolean,
  authRole?  : string
};

export const RouteWithLayout: FC<RouteWithLayoutProps> = ({
  exact,
  path,
  layout: Layout,
  view: View,
  viewParams,
  authPublic,
  authRole }) => {

  const { isAuth, checkAuth, refreshAuth } = useContext(AuthContext);
  const location = useLocation();


  useEffect(() => {
    // debugger;
    // if (isAuth !== null) {
      // if AuthProvider is already initialized
      checkAuth();
      console.log(`ROUTE: [${authPublic ? "PUBL ✔" : ("AUTH " + (isAuth ? "🔑" : "🔒"))}] ${path}`);
    // } else {
    //   refreshAuth();
    // }
  }, [path, authPublic, isAuth, checkAuth]);


  const redirectNotAuth = () => {
    console.log(location);
    return <Redirect
    to={{
      pathname: "/login",
      state: { referrer: location }
    }}
  />
  };

  return (
    <Route
      exact={!!exact}
      path={path}
      render=
      {
        (matchProps) =>
          (!authPublic && isAuth === false)
            ?
            redirectNotAuth()
            :
            <Layout>
              {
                (isAuth === null)
                  ? ""
                  : <View {...matchProps.match.params} {...viewParams}/>
              // : <React.Fragment>{JSON.stringify(matchProps.match.params)}</React.Fragment>
              }
            </Layout>
      }
    />
  );
}
