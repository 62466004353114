import React, { FC, useState, useContext, useCallback, useEffect } from "react";
import { CognitoIdentity, CognitoIdentityServiceProvider, config } from "aws-sdk";
import AWS, { CognitoIdentityCredentials } from "aws-sdk";
import { CognitoRefreshToken, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import JsonView from "react-json-view";

import { AuthContext } from "auth/AuthProvider"
import { CognitoAuth } from "auth/CognitoAuth";
import { iri6params } from "auth/AwsParams";
import { callDb3 } from "auth/db";
import GoogleLogin from "react-google-login";
// import { makeStyles, Theme } from "@material-ui/core/styles";


const cognitoAuth = new CognitoAuth(iri6params);

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     display: "flex"
//   }
// }));


// export type RmComponentProps = {
//   id     : string
//   field1?: string
// }


export const AuthInfo = () => {

  //#region DECLARATIONS
  // const classes = useStyles();
  const { logIn, logOut, isAuth, authError, /* isAuth$ */ } = useContext(AuthContext);

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");

  const [inited, setInited] = useState(false);
  const [cognitoUser, setCognitoUser] = useState({});
  const [payloadIdToken, setPayloadIdToken] = useState({});
  const [payloadAccessToken, setPayloadAccessToken] = useState({});
  //#endregion


  //#region HANDLERS
  const login = async () => {
    // console.log(`${user}:${pwd}`);
    const res = await logIn(user, pwd);
    // console.log(res);
  };


  function ssoLogin() {
    console.log("SSO LOGIN");
  }


  const logout = () => {
    logOut();
  }


  function isAuthenticated() {

    cognitoAuth.isAuthenticated()
      .then(isAuth => {
        console.log(isAuth);
      })
      .catch(err => {
        console.log(err);
      });
  }

  async function refresh() {
    const res = await cognitoAuth.refreshAuth();
    console.log(res);

    if (res) {
      const idP = (await cognitoAuth.getIdToken())!.decodePayload();
      const acP = (await cognitoAuth.getAccessToken())!.decodePayload();
      setPayloadIdToken(idP);
      setPayloadAccessToken(acP);
      parseJwtTs(idP);
      parseJwtTs(acP);

      const c = cognitoAuth.getCurrentUser();
      setCognitoUser(c || {});
      console.log(c);
    }
  }

  function db() {
    callDb3(config.credentials);
  }


  function parseJwtTs(data: any) {
    const keys = ["iat", "exp", "auth_time"];
    const keyValues = keys.map(x => new Object({ key: x, value: new Date(data[x] * 1000) }));
    console.log(keyValues);

    // keys.forEach(key => {
    //   if(data[key]) {
    //     data[key] = new Date(data[key] * 1000);
    //   }
    // });

    return data;
  }

  function onGoogleSignIn(data:any) {
    // var profile = googleUser.getBasicProfile();
    // console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    // console.log('Name: ' + profile.getName());
    // console.log('Image URL: ' + profile.getImageUrl());
    // console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.
    console.log(data);

    cognitoAuth.initCredentialsGoogle(data.tokenId, data.accessToken);
  }
  //#endregion


  //#region EFFECTS
  useEffect(() => {
    if(!inited) {
      setInited(true);
      refresh();
    }
  }, [inited]);
  //#endregion

  return (
    <>
      <form>
        <label>
          <span>Username</span>
          <input type="text" value={user} onChange={e => setUser(e.target.value)} />
        </label>
        <label>
          <span>Password</span>
          <input type="password" value={pwd} onChange={e => setPwd(e.target.value)} />
        </label>
        <button type="button" onClick={() => login()}>Log In</button>
        <button type="button" onClick={() => logout()}>Log Out</button>
        <button type="button" onClick={() => refresh()}>refresh</button>
        <button type="button" onClick={() => isAuthenticated()}>isAuth</button>
        <button type="button" onClick={() => cognitoAuth.checkCredentialsValidity()}>checkCreds</button>
        <button type="button" onClick={() => db()}>DB</button>
      </form>

      <form>
      <GoogleLogin
        clientId="932714005535-vo24p5avfg9l86j5g3uv0d7e0g25bofo.apps.googleusercontent.com"
        buttonText="Login"
        onSuccess={onGoogleSignIn}
        onFailure={onGoogleSignIn}
        cookiePolicy={'single_host_origin'}
      />
      </form>

      <h2>ID TOKEN PAYLOAD</h2>
      <JsonView src={payloadIdToken} enableClipboard={true} displayDataTypes={false} name={null} theme="monokai" />
      <h2>ID TOKEN PAYLOAD</h2>
      <JsonView src={payloadAccessToken} enableClipboard={true} displayDataTypes={false} name={null} theme="monokai" />
      <h2>COGNITO USER INFO</h2>
      <JsonView src={cognitoUser} enableClipboard={true} displayDataTypes={false} collapsed={1} name={null} theme="monokai" />
    </>
  );
};
