import AWS, { config, CognitoIdentityCredentials, STS, CognitoIdentity } from "aws-sdk";
import {
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
    CognitoUserAttribute,
    CognitoIdToken,
    CognitoAccessToken,
    AuthenticationDetails,
    ISignUpResult,
} from "amazon-cognito-identity-js";

import { AwsParams } from "./AwsParams";

export class CognitoAuth {

    private params  : AwsParams;
    private userPool: CognitoUserPool;


    private credentialsInitialized = false;

    private _credentials?: CognitoIdentityCredentials;
    public get Credentials() {
        return this._credentials;
    }


    constructor(params: AwsParams) {
        this.params   = params;

        this.userPool = new CognitoUserPool({
            UserPoolId: params.IdentityPoolId,
            ClientId  : params.ClientId
        });
        
        config.region = params.region;
    }


    private async initCredentials(session: CognitoUserSession) {
        if (session.isValid()) {
            const key = "cognito-idp." + this.params.region + ".amazonaws.com/" + this.params.IdentityPoolId;
            const logins: Record<string, string> = {};
            logins[key] = session.getIdToken().getJwtToken();
            config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: this.params.UserPoolId,
                Logins: logins
            });
        }

        const credCheck = await this.checkCredentialsValidity();
        this.credentialsInitialized = credCheck;
        this._credentials = config.credentials as CognitoIdentityCredentials;
        // debugger;
        return credCheck;
    }


    public initCredentialsGoogle(idToken: string, accessToken: string) {
        const key = "accounts.google.com";
            const logins: Record<string, string> = {};
            logins[key] = idToken;
            config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: this.params.UserPoolId,
                Logins: logins
            });

        this.checkCredentialsValidity()
        .then(() => {

            const cid = (config!.credentials! as CognitoIdentityCredentials);
            console.log(cid);
            debugger;

            const ss = new CognitoUserSession({IdToken: new CognitoIdToken({IdToken: idToken}) , AccessToken: new CognitoAccessToken({AccessToken: accessToken})});
const v = ss.isValid();
debugger
            const identity = new CognitoIdentity(config);
            identity.getId({IdentityPoolId: this.params.UserPoolId},
                (err, data) =>{
                    debugger;
                    console.log(err);
                    console.log(data);
                });

            console.log(cid.identityId);
identity.describeIdentity({IdentityId: cid.identityId},
    (err, data) => {
            debugger;
            console.log(err);
            console.log(data);
    })

            // identity.getOpenIdTokenForDeveloperIdentity({
            //     IdentityPoolId: "us-east-1:0d93dcd5-f1ee-4586-bbb1-03d949e347d6",
            //     IdentityId: cid.identityId, 
            //     Logins : {
            //         'cognito-identity.amazonaws.com': cid.sessionToken
            //      }
            // },
            // (err, data) => {
            //     debugger;
            //     console.log(err);
            //     console.log(data);
            // });
            // identity.getId({
            //     IdentityPoolId: "us-east-1:0d93dcd5-f1ee-4586-bbb1-03d949e347d6",
            //     Logins: logins
            // },
            // (err, data) => {
            //     debugger;
            //     console.log(err);
            //     console.log(data);

            //     identity.getCredentialsForIdentity({
            //             IdentityId: data.IdentityId!, 
            //             Logins : logins
            //         },
            //         (err, data) => {
            //             debugger;
            //             console.log(err);
            //             console.log(data);
            //         });


            //         identity.getOpenIdToken({
            //             IdentityId: data.IdentityId!, 
            //             Logins : logins
            //         },
            //         (err, data) => {
            //             debugger;
            //             console.log(err);
            //             console.log(data);
            //         });
            // });

            console.log((config.credentials as CognitoIdentityCredentials));
            console.log((config.credentials as CognitoIdentityCredentials).expireTime);
            // debugger;
            (config.credentials as CognitoIdentityCredentials).refreshPromise()
            .then(x=> console.log((config.credentials as CognitoIdentityCredentials).expireTime))
            .catch(err =>console.log(err));

            const u = this.userPool.getCurrentUser();
            // debugger;

            const cognitoUser = new CognitoUser({
                Username: "Google_106300867436124168596",
                Pool    : this.userPool
            });

            const cc = cognitoUser.getSignInUserSession();
            // const cc = cognitoUser.authenticateUser;
            // debugger;

            // const authDetails = new AuthenticationDetails({
            //     Username: "Google_106300867436124168596",
            // });

            // cognitoUser.authenticateUser(
            // authDetails,
            // {
            //     onSuccess: (session) => {
            //         debugger;
            //     },
            //     onFailure: (err) => {
            //         debugger;
            //     },
            //     newPasswordRequired: () => {
            //         debugger;
            //     },
            // });

            // new CognitoUserSession({
            //     AccessToken
            // });

            

            // const cognitoUser = new CognitoUser({
            //     // Username: "Google_106300867436124168596",
            //     Username: "jany@iri6.com",
            //     Pool    : this.userPool
            // });

            // const authDetails = new AuthenticationDetails({
            //     // Username: "Google_106300867436124168596"
            //     Username: "jany@iri6.com"
            // });

            // cognitoUser.authenticateUser(
            //     authDetails,
            //     {
            //         onSuccess: (session) => {
            //             debugger;
            //             this.initCredentials(session);
            //             // resolve({ success: true });
            //         },
            //         onFailure: (err) => {
            //             // resolve({ success: false, error: err });
            //             console.log(err);
            //             debugger;
            //         },
            //         newPasswordRequired: () => {
            //             // resolve({ success: false, error: "New Password Is Required" });
            //         },
            //     });
        });

    }


    checkCredentialsValidity() {
        return new Promise<boolean>((resolve, reject) => {
            if (!config.credentials) {
                console.log("Credential missing in config");
                resolve(false);
            }
            else {
                (config.credentials as CognitoIdentityCredentials)
                    .get(err => {
                        if (err) {
                            console.log(err);
                            resolve(false);
                        } else {
                            console.log("Credentials OK");
                            resolve(true);

                            // debugger;
                            // Credentials will be available when this function is called.
                            var accessKeyId = config?.credentials?.accessKeyId;
                            var secretAccessKey = config?.credentials?.secretAccessKey;
                            var sessionToken = config?.credentials?.sessionToken;

                            // new CognitoUserSession({})

                            // debugger;
                            const params = {
                                IdentityId: (config!.credentials! as CognitoIdentityCredentials).identityId,
                                d: (config!.credentials! as CognitoIdentityCredentials),
                            }
                            console.log(params);
                            
                            var cognitoidentity = new AWS.CognitoIdentity();
                            // debugger;
                            // cognitoidentity.describeIdentity({}, x=>console.log(x))
                            // debugger;
                            // cognitoidentity.getId({IdentityPoolId: "us-east-1:0d93dcd5-f1ee-4586-bbb1-03d949e347d6"}, x=>console.log(x))

                            // cognitoidentity.getCredentialsForIdentity({
                            //     IdentityPoolId: this.params.UserPoolId,
                            //     Logins: logins
                            // }, (err, data) => data.Credentials.


                            // debugger;
                            // cognitoidentity.getOpenIdToken({}, function(err, data) {
                            //     if (err){
                            //         console.log(err, err.stack); // an error occurred
                            //     }else{
                            //     }
                            // });


                            // https://stackoverflow.com/questions/32956984/the-security-token-included-in-the-request-is-invalid-aws-js-sdk

                            //   var sts = new STS();

                            //   sts.getCallerIdentity((err, data) => {

                            //   });

                            //   const params2:AWS.STS.GetCallerIdentityRequest = {

                            //   };

                            // sts.getCallerIdentity(params2, function (err, data) {
                            //   if (err) console.log(err, err.stack); // an error occurred
                            //   else console.log(data);           // successful response
                            //   /*
                            //   data = {
                            //    Account: "123456789012", 
                            //    Arn: "arn:aws:iam::123456789012:user/Alice", 
                            //    UserId: "AKIAI44QH8DHBEXAMPLE"
                            //   }
                            //   */
                            // });

                        }
                    });
            }
        });
    }


    getCurrentUser() {
        return this.userPool.getCurrentUser()
    }


    isAuthenticated() {
        return new Promise<boolean>((resolve, _reject) => {
            const user = this.getCurrentUser();
            if (user) {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err || !session || !session.isValid()) {
                        resolve(false);
                    } else {
                        // debugger;
                        resolve(this.credentialsInitialized || this.initCredentials(session));
                    }
                });
            } else {
                resolve(false);
            }
        });
    }


    refreshAuth() {
        return new Promise<Date | null>((resolve, _reject) => {

            this.credentialsInitialized = false;

            const user = this.getCurrentUser();
            if (!user) {
                // no user
                resolve(null);
            } else {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err || !session || !session.isValid()) {
                        resolve(null);
                    }
                    else {
                        user.refreshSession(
                            session.getRefreshToken(),
                            err => {
                                if (err) {
                                    resolve(null)
                                } else {
                                    this.initCredentials(session);
                                    const exp = session.getIdToken().getExpiration();
                                    const date = new Date(exp * 1000);
                                    resolve(date);
                                }
                            });
                    }
                })
            }
        });
    }


    authenticate(username: string, password: string) {
        return new Promise<{ success: boolean, error?: any }>((resolve, _reject) => {
            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password
            });
            const cognitoUser = new CognitoUser({
                Username: username,
                Pool    : this.userPool
            });

            cognitoUser.authenticateUser(
                authDetails,
                {
                    onSuccess: async (session) => {
                        const credCheck = await this.initCredentials(session);
                        // debugger;
                        resolve({ success: credCheck });
                    },
                    onFailure: (err) => {
                        resolve({ success: false, error: err });
                    },
                    newPasswordRequired: () => {
                        resolve({ success: false, error: "New Password Is Required" });
                    },
                });
        });
    }


    signup(email: string, username: string, password: string) {
        return new Promise<{ result?: ISignUpResult, error?: Error }>((resolve, reject) => {
            const userAttributes = [
                new CognitoUserAttribute({
                    Name: "email",
                    Value: email
                })
            ];

            this.userPool.signUp(
                username,
                password,
                userAttributes,
                [],
                (error?, result?) => {
                    resolve({ result, error });
                });
        });
    }


    confirmRegistration(username: string, code: string) {
        return new Promise<{ result?: any, error?: any }>((resolve, _reject) => {
            const cognitoUser = new CognitoUser({
                Username: username,
                Pool: this.userPool
            });
            cognitoUser.confirmRegistration(
                code,
                true,
                (error, result) => {
                    resolve({ result, error });
                });
        });
    }


    logout() {
        this.getCurrentUser()?.signOut();
        config.credentials = null;
        this.credentialsInitialized = false;
    }


    getIdToken() {
        return new Promise<CognitoIdToken | null>((resolve, _reject) => {
            const user = this.getCurrentUser();
            if (!user) {
                resolve(null);
            } else {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err || !session || !session.isValid()) {
                        resolve(null);
                    }
                    else {
                        resolve(session.getIdToken());
                    }
                })
            }
        });
    }


    getAccessToken() {
        return new Promise<CognitoAccessToken | null>((resolve, _reject) => {
            const user = this.getCurrentUser();
            if (!user) {
                resolve(null);
            } else {
                user.getSession((err: Error | null, session: CognitoUserSession | null) => {
                    if (err || !session || !session.isValid()) {
                        resolve(null);
                    }
                    else {
                        resolve(session.getAccessToken());
                    }
                })
            }
        });
    }
}